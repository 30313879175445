
import {
  computed,
  defineComponent,
  onUnmounted,
  reactive,
  ref,
} from 'vue';
import { useRouter } from 'vue-router';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import {
  Undo as IconChangeCamera,
} from '@vicons/fa';
import AButton from '@/components/AButton.vue';
import AContainer from '@/components/AContainer.vue';
import AModal from '@/components/AModal.vue';
import StreamPlayer from '@/components/StreamPlayer.vue';
import TheNavbar from '@/components/TheNavbar.vue';
import ToolbarNoise from '@/components/ToolbarNoise.vue';
import WatcherPeer from '@/components/Peer';
import { useChannel } from '@/services/api/privateChannel';
import { WatcherRequestEvent } from '@/services/api/types';
import { waitForValue } from '@/services/utils';
import RouteName from '@/router/RouteName';
import { NoiseStatusMessage, NoiseType } from '@/services/noise';

type PeerInfo = {
  publicKey: string,
  isNoiseHost: boolean,
}

export default defineComponent({
  components: {
    AButton,
    AContainer,
    AModal,
    IconChangeCamera,
    StreamPlayer,
    TheNavbar,
    ToolbarNoise,
    VueQrcode,
    WatcherPeer,
  },
  setup() {
    const isAddCameraModalOpen = ref(true);
    const { namespace, isOpen: isChannelOpen, connection } = useChannel();

    const noise = reactive({
      active: false,
      volume: -20,
      type: 'brown' as NoiseType,
    });

    const noiseStatusPayload = computed<NoiseStatusMessage>(() => noise);
    const noiseStatusMessage = computed(() => `noise|${JSON.stringify(noiseStatusPayload.value)}`);

    const updateNoise = (payload: { type: NoiseType, volume: number, active: boolean }) => {
      noise.type = payload.type;
      noise.volume = payload.volume;
      noise.active = payload.active;
      return noiseStatusMessage.value;
    };

    const toggleNoise = (value?: boolean) => {
      noise.active = value !== undefined ? !!value : !noise.active;
      return noiseStatusMessage.value;
    };

    const toggleCamera = () => 'camera|toggle';

    const router = useRouter();

    const watcherLink = computed(() => {
      if (!namespace.value) return undefined;
      const path = router.resolve({
        name: RouteName.Watcher,
        params: { namespace: namespace.value },
      }).fullPath;
      return `${window.location.origin}${path}`;
    });

    const peers = ref<Map<string, PeerInfo>>(new Map());

    const createPeer = (payload: WatcherRequestEvent) => {
      if (!peers.value.has(payload.id)) {
        peers.value.set(payload.id, {
          publicKey: payload.publicKey,
          isNoiseHost: peers.value.size === 0,
        });
      }
    };

    const onPeerClose = (id: string) => {
      const peer = peers.value.get(id);
      peers.value.delete(id);
      if (peer && peer.isNoiseHost) {
        toggleNoise(false);
        if (peers.value.size) {
          const [peerInfo] = peers.value.values();
          peerInfo.isNoiseHost = true;
        }
      }
    };

    const handleWatcherRequests = (payload: WatcherRequestEvent) => {
      createPeer(payload);
      isAddCameraModalOpen.value = false;
    };

    waitForValue(isChannelOpen).then(() => {
      if (!connection.socket.value) return;
      connection.socket.value.on('watcher-request', handleWatcherRequests);
    });

    onUnmounted(() => {
      if (connection.socket.value) {
        connection.socket.value.off('props.channel', handleWatcherRequests);
      }
    });

    return {
      namespace,
      peers,
      onPeerClose,
      watcherLink,
      isAddCameraModalOpen,
      isChannelOpen,
      noise,
      updateNoise,
      toggleNoise,
      toggleCamera,
    };
  },
});
