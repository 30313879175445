<template>
  <div class="a-select">
    <select
      class="a-select__input"
      v-model="model"
    >
      <option v-for="option in options" :key="option">{{ option }}</option>
    </select>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    modelValue: String,
    options: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const model = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit('update:modelValue', value);
      },
    });
    return {
      model,
    };
  },
});
</script>

<style lang="scss" scoped>
.a-select {
  @apply relative inline-flex;
  &::after {
    content: '';
    pointer-events: none;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-top-color: currentColor;
    top: calc(50% - 2px);
    right: 0;
    transform: scaleX(80%);
    @apply block absolute mx-5;
  }
}
.a-select__input {
  appearance: none;
  @apply flex-grow;
  @apply bg-white border border-gray-300 rounded-lg shadow-md;
  @apply py-2 pl-5 pr-11;
  @apply ring-green-500 ring-opacity-40;
  &:focus {
    @apply outline-none ring-2 border-green-500;
  }
  &:active {
    @apply ring-4 border-green-500;
  }
}
</style>
