<template>
  <div class="noise-toolbar__wrapper relative opacity-50 hover:opacity-80 mb-2">
    <div
      v-if="active"
      class="
        w-3/4 h-3/4 absolute top-1/8 left-1/8
        bg-green-500 rounded-lg animate-ping
        pointer-events-none
      "
    />
    <button
      class="
        noise-toolbar__play-button
        relative
        w-full
        flex flex-col items-center
        p-2
        bg-black
        rounded-lg shadow-xl
        text-white text-center
        cursor-pointer
        z-10
      "
      @click="toggle"
    >
      <IconNoise
        class="h-5 noise-toolbar__icon-noise"
        :class="{ 'text-green-300': active }"
      />
      <IconPause v-if="active" class="h-5 p-1 noise-toolbar__icon-noise-hover" />
      <IconPlay v-if="!active" class="h-5 p-1 noise-toolbar__icon-noise-hover" />
      <div class="text-center text-xs">szum</div>
    </button>
    <button
      class="
        noise-toolbar__settings-button
        absolute h-full w-full top-0 -left-full
        text-gray-600
        hover:text-green-800
        bg-white
        rounded-tl-lg
        rounded-bl-lg
        border border-black border-opacity-30
      "
      @click="showSettings"
    >
      <IconCog class="h-5 mx-auto" />
    </button>
  </div>

  <AModal v-if="isNoiseSettingsModalOpen" @close="closeSettings()">
    <div class="bg-white p-8 rounded-xl text-center max-h-full overflow-auto">
      <h2 class="text-xl font-medium mb-8">Ustawienia szumu</h2>
      <div class="mt-6 grid grid-cols-3 gap-4 items-center">
        <div class="text-right">
          rodzaj<span class="hidden sm:inline"> szumu</span>
        </div>
        <ASelect
          v-model="settingsModel.type"
          class="col-span-2"
          :options="['brązowy', 'biały', 'różowy']"
        />
      </div>
      <div class="mt-6 grid grid-cols-3 gap-4 items-center">
        <div class="text-right">
          głośność:
        </div>
        <ASelect
          v-model="settingsModel.volume"
          class="col-span-2"
          :options="['25', '50', '75', '100']"
        />
      </div>
      <div class="mt-8">
        <AButton text @click="closeSettings()">Anuluj</AButton>
        <AButton @click="applySettings()">
          {{ active ? 'Zastosuj zmiany' : 'Uruchom szum' }}
        </AButton>
      </div>
    </div>
  </AModal>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  reactive,
  readonly,
  ref,
} from 'vue';
import {
  Cog as IconCog,
  WaveSquare as IconNoise,
  Play as IconPlay,
  Pause as IconPause,
} from '@vicons/fa';
import AButton from '@/components/AButton.vue';
import ASelect from '@/components/ASelect.vue';
import AModal from '@/components/AModal.vue';
import { NOISE_TYPES, NoiseType } from '@/services/noise';

export default defineComponent({
  components: {
    AButton,
    AModal,
    ASelect,
    IconCog,
    IconNoise,
    IconPlay,
    IconPause,
  },
  props: {
    active: Boolean,
    volume: {
      type: Number,
      default: -8,
    },
    type: {
      type: String as PropType<NoiseType>,
      default: 'white',
    },
  },
  emits: ['update', 'toggle'],
  setup(props, { emit }) {
    const isNoiseSettingsModalOpen = ref(false);
    const settingsModel = reactive({
      type: 'biały' as typeof NOISE_TYPES[keyof typeof NOISE_TYPES],
      volume: '100',
    });

    const valToDb = (percentage: string) => (+percentage - 100) / 3;
    const dbToVal = (db: number) => `${Math.min(100, Math.max(25, Math.round((db * 3 + 100) / 25) * 25))}`;
    const valToType = (val: typeof NOISE_TYPES[NoiseType]) => Object.entries(NOISE_TYPES).find(([, v]) => v === val)?.[0] || 'white';
    const typeToVal = (type: NoiseType) => NOISE_TYPES[type] || 'biały';

    const showSettings = () => {
      settingsModel.type = typeToVal(props.type);
      settingsModel.volume = dbToVal(props.volume);
      isNoiseSettingsModalOpen.value = true;
    };

    const applySettings = (noActivate = false) => {
      emit('update', {
        type: valToType(settingsModel.type),
        volume: valToDb(settingsModel.volume),
        active: noActivate ? props.active : true,
      });
      isNoiseSettingsModalOpen.value = false;
    };

    const closeSettings = () => {
      if (!props.active) applySettings(true);
      isNoiseSettingsModalOpen.value = false;
    };

    const toggle = () => emit('toggle');
    return {
      settingsModel,
      closeSettings,
      showSettings,
      applySettings,
      isNoiseSettingsModalOpen: readonly(isNoiseSettingsModalOpen),
      toggle,
    };
  },
});
</script>

<style lang="scss" scoped>

.noise-toolbar__settings-button {
  transform: translateX(100%);
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;
  .noise-toolbar__wrapper:hover & {
    transform: translateX(0);
    opacity: 1;
  }
}

.noise-toolbar__icon-noise-hover {
  display: none;
}

.noise-toolbar__play-button {
  &:hover {
    .noise-toolbar__icon-noise-hover {
      display: block;
    }
    .noise-toolbar__icon-noise {
      display: none;
    }
  }
  .noise-toolbar__wrapper:hover & {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
