
import {
  defineComponent,
  PropType,
  reactive,
  readonly,
  ref,
} from 'vue';
import {
  Cog as IconCog,
  WaveSquare as IconNoise,
  Play as IconPlay,
  Pause as IconPause,
} from '@vicons/fa';
import AButton from '@/components/AButton.vue';
import ASelect from '@/components/ASelect.vue';
import AModal from '@/components/AModal.vue';
import { NOISE_TYPES, NoiseType } from '@/services/noise';

export default defineComponent({
  components: {
    AButton,
    AModal,
    ASelect,
    IconCog,
    IconNoise,
    IconPlay,
    IconPause,
  },
  props: {
    active: Boolean,
    volume: {
      type: Number,
      default: -8,
    },
    type: {
      type: String as PropType<NoiseType>,
      default: 'white',
    },
  },
  emits: ['update', 'toggle'],
  setup(props, { emit }) {
    const isNoiseSettingsModalOpen = ref(false);
    const settingsModel = reactive({
      type: 'biały' as typeof NOISE_TYPES[keyof typeof NOISE_TYPES],
      volume: '100',
    });

    const valToDb = (percentage: string) => (+percentage - 100) / 3;
    const dbToVal = (db: number) => `${Math.min(100, Math.max(25, Math.round((db * 3 + 100) / 25) * 25))}`;
    const valToType = (val: typeof NOISE_TYPES[NoiseType]) => Object.entries(NOISE_TYPES).find(([, v]) => v === val)?.[0] || 'white';
    const typeToVal = (type: NoiseType) => NOISE_TYPES[type] || 'biały';

    const showSettings = () => {
      settingsModel.type = typeToVal(props.type);
      settingsModel.volume = dbToVal(props.volume);
      isNoiseSettingsModalOpen.value = true;
    };

    const applySettings = (noActivate = false) => {
      emit('update', {
        type: valToType(settingsModel.type),
        volume: valToDb(settingsModel.volume),
        active: noActivate ? props.active : true,
      });
      isNoiseSettingsModalOpen.value = false;
    };

    const closeSettings = () => {
      if (!props.active) applySettings(true);
      isNoiseSettingsModalOpen.value = false;
    };

    const toggle = () => emit('toggle');
    return {
      settingsModel,
      closeSettings,
      showSettings,
      applySettings,
      isNoiseSettingsModalOpen: readonly(isNoiseSettingsModalOpen),
      toggle,
    };
  },
});
