<template>
  <div class="stream-player">
    <video
      ref="videoRef"
      :style="videoStyle"
      autoplay
      playsinline
      controls
      @play="onPlay"
      v-bind="videoAttrs"
    />
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  toRef,
  watch,
} from 'vue';

export default defineComponent({
  props: {
    stream: {
      type: MediaStream,
      required: true,
    },
    videoAttrs: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const videoRef = ref<HTMLVideoElement>();
    const videoWidth = ref<number>(400);
    const videoHeight = ref<number>(300);
    const videoStyle = computed(() => ({
      aspectRatio: `${videoWidth.value} / ${videoHeight.value}`,
    }));
    const updateStream = (stream: MediaStream) => {
      if (!videoRef.value) return;
      if ('srcObject' in videoRef.value) {
        videoRef.value.srcObject = stream;
      } else {
        (videoRef.value as HTMLVideoElement).src = window.URL.createObjectURL(stream);
      }
    };

    const onPlay = () => {
      if (videoRef.value?.videoHeight) videoHeight.value = videoRef.value.videoHeight;
      if (videoRef.value?.videoWidth) videoWidth.value = videoRef.value.videoWidth;
    };

    onMounted(() => {
      updateStream(props.stream);
    });
    watch(toRef(props, 'stream'), updateStream);

    return {
      onPlay,
      videoRef,
      videoStyle,
    };
  },
});
</script>

<style lang="scss" scoped>
.stream-player {
  position: relative;
  video {
    width: 100%;
    height: 100%;
  }
}
</style>
