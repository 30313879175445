<template>
  <TheNavbar />

  <AContainer class="listener__container text-center flex flex-col justify-center items-center">
    <template v-if="!isChannelOpen">
      <div
        style="border-top-color: transparent"
        class="w-16 h-16 border-4 border-green-600 border-dashed rounded-full animate-spin"
      />
    </template>
    <template v-else-if="namespace">
      <div class="grid grid-flow-col auto-cols-1fr justify-stretch w-full flex-shrink">
        <WatcherPeer
          v-for="([recipientId, recipientInfo]) in peers.entries()"
          :key="recipientId"
          id="listener"
          :recipientId="recipientId"
          :recipientPublicKey="recipientInfo.publicKey"
          :namespace="namespace"
          initiator
          @close="onPeerClose(recipientId)"
        >
          <template
            v-slot="{
              connected,
              incomingStream,
              send,
            }"
          >
            <div class="relative overflow-hidden">
              <div
                v-if="connected"
                class="absolute right-4 top-4 z-10 flex flex-col"
              >
                <ToolbarNoise
                  v-if="recipientInfo.isNoiseHost"
                  :active="noise.active"
                  :volume="noise.volume"
                  :type="noise.type"
                  @toggle="send(toggleNoise())"
                  @update="send(updateNoise($event))"
                />
                <button
                  class="
                    relative
                    flex flex-col items-center
                    p-2 mb-2
                    bg-black
                    rounded-lg shadow-xl
                    text-white text-center
                    opacity-50 hover:opacity-80
                    cursor-pointer
                    z-10
                  "
                  @click="send(toggleCamera())"
                >
                  <IconChangeCamera class="h-5 noise-toolbar__icon-noise" />
                  <div class="text-center text-xs">kamera</div>
                </button>
              </div>
              <div class="flex max-h-full max-w-full">
                <!-- <div>watcher peer {{ recipientId }}</div> -->
                <StreamPlayer
                  v-if="incomingStream"
                  class="listener__player flex-grow"
                  :stream="incomingStream"
                />
              </div>
            </div>
          </template>
        </WatcherPeer>
      </div>

      <AButton
        class="my-8"
        large
        @click="isAddCameraModalOpen = true"
      >
        Dodaj kamerę
      </AButton>
    </template>
  </AContainer>

  <AModal
    v-if="watcherLink && isAddCameraModalOpen"
    @close="isAddCameraModalOpen = false"
  >
    <div class="bg-white p-8 rounded-xl text-center max-h-full overflow-auto">
      <h2 class="text-xl font-medium mb-6">Dodaj kamerę</h2>
      <p>Korzystając z urządzenia, które będzie pełniło rolę kamery:</p>
      <p class="mt-6">zeskanuj kod QR</p>
      <VueQrcode
        class="mx-auto opacity-70 w-80"
        :value="watcherLink"
        :options="{
          width: 240,
        }"
      />
      <p class="mt-6">lub odwiedź link</p>
      <p class="mt-2">
        <a
          class="text-green-600 hover:text-green-500"
          :href="watcherLink"
        >
          {{ watcherLink }}
        </a>
      </p>
    </div>
  </AModal>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onUnmounted,
  reactive,
  ref,
} from 'vue';
import { useRouter } from 'vue-router';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import {
  Undo as IconChangeCamera,
} from '@vicons/fa';
import AButton from '@/components/AButton.vue';
import AContainer from '@/components/AContainer.vue';
import AModal from '@/components/AModal.vue';
import StreamPlayer from '@/components/StreamPlayer.vue';
import TheNavbar from '@/components/TheNavbar.vue';
import ToolbarNoise from '@/components/ToolbarNoise.vue';
import WatcherPeer from '@/components/Peer';
import { useChannel } from '@/services/api/privateChannel';
import { WatcherRequestEvent } from '@/services/api/types';
import { waitForValue } from '@/services/utils';
import RouteName from '@/router/RouteName';
import { NoiseStatusMessage, NoiseType } from '@/services/noise';

type PeerInfo = {
  publicKey: string,
  isNoiseHost: boolean,
}

export default defineComponent({
  components: {
    AButton,
    AContainer,
    AModal,
    IconChangeCamera,
    StreamPlayer,
    TheNavbar,
    ToolbarNoise,
    VueQrcode,
    WatcherPeer,
  },
  setup() {
    const isAddCameraModalOpen = ref(true);
    const { namespace, isOpen: isChannelOpen, connection } = useChannel();

    const noise = reactive({
      active: false,
      volume: -20,
      type: 'brown' as NoiseType,
    });

    const noiseStatusPayload = computed<NoiseStatusMessage>(() => noise);
    const noiseStatusMessage = computed(() => `noise|${JSON.stringify(noiseStatusPayload.value)}`);

    const updateNoise = (payload: { type: NoiseType, volume: number, active: boolean }) => {
      noise.type = payload.type;
      noise.volume = payload.volume;
      noise.active = payload.active;
      return noiseStatusMessage.value;
    };

    const toggleNoise = (value?: boolean) => {
      noise.active = value !== undefined ? !!value : !noise.active;
      return noiseStatusMessage.value;
    };

    const toggleCamera = () => 'camera|toggle';

    const router = useRouter();

    const watcherLink = computed(() => {
      if (!namespace.value) return undefined;
      const path = router.resolve({
        name: RouteName.Watcher,
        params: { namespace: namespace.value },
      }).fullPath;
      return `${window.location.origin}${path}`;
    });

    const peers = ref<Map<string, PeerInfo>>(new Map());

    const createPeer = (payload: WatcherRequestEvent) => {
      if (!peers.value.has(payload.id)) {
        peers.value.set(payload.id, {
          publicKey: payload.publicKey,
          isNoiseHost: peers.value.size === 0,
        });
      }
    };

    const onPeerClose = (id: string) => {
      const peer = peers.value.get(id);
      peers.value.delete(id);
      if (peer && peer.isNoiseHost) {
        toggleNoise(false);
        if (peers.value.size) {
          const [peerInfo] = peers.value.values();
          peerInfo.isNoiseHost = true;
        }
      }
    };

    const handleWatcherRequests = (payload: WatcherRequestEvent) => {
      createPeer(payload);
      isAddCameraModalOpen.value = false;
    };

    waitForValue(isChannelOpen).then(() => {
      if (!connection.socket.value) return;
      connection.socket.value.on('watcher-request', handleWatcherRequests);
    });

    onUnmounted(() => {
      if (connection.socket.value) {
        connection.socket.value.off('props.channel', handleWatcherRequests);
      }
    });

    return {
      namespace,
      peers,
      onPeerClose,
      watcherLink,
      isAddCameraModalOpen,
      isChannelOpen,
      noise,
      updateNoise,
      toggleNoise,
      toggleCamera,
    };
  },
});
</script>

<style lang="scss">
.listener__container {
  min-height: calc(100vh - 80px);
}
.listener__player {
  max-height: calc(100vh - 280px);
}
</style>
