
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    modelValue: String,
    options: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const model = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit('update:modelValue', value);
      },
    });
    return {
      model,
    };
  },
});
