
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['close'],
  setup(props, { emit }) {
    const close = () => emit('close');
    return {
      close,
    };
  },
});
