<template>
  <teleport to="body">
    <div
      class="
        a-modal
        fixed
        top-0
        z-50
        w-full h-full
        flex items-center justify-center
        bg-black bg-opacity-50
        cursor-pointer
      "
      @click.self="close"
    >
      <slot />
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['close'],
  setup(props, { emit }) {
    const close = () => emit('close');
    return {
      close,
    };
  },
});
</script>
<style lang="scss" scoped>
.a-modal > ::v-deep(*) {
  cursor: auto;
}
</style>
